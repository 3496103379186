import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {
  const navige = useNavigate();
  function handleClick() {
    navige('/menu');
  }
  return (
    <div className="hero">
      <div className="sr-only">Background of juscy fried chicken</div>
      <h1>Chicken Kings </h1>
      <p>
        Ζουμερό κοτόπουλο, χρυσαφί κρούστα, απολαύστε τη γεύση μας στο πιάτο
        σας!
      </p>
      <button
        className="hero-btn"
        aria-label="link to menu page"
        onClick={handleClick}
      >
        Δείτε το Menu
      </button>
    </div>
  );
}
