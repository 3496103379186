import React, { useEffect, useRef } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LandingPage from '../components/LandingPage';
import MainContent from '../components/MainContent';

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const headerRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const mediaQueryList = window.matchMedia('(min-width: 800px)');
      if (mediaQueryList.matches && window.scrollY > window.innerHeight * 0.8) {
        headerRef.current.classList.add('bg');
      } else if (
        (window.scrollY > window.innerHeight * 0.8 &&
          window.scrollY < window.innerHeight * 1.8) ||
        window.scrollY > window.innerHeight * 2.5
      ) {
        headerRef.current.classList.add('bg');
      } else headerRef.current.classList.remove('bg');
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Header ref={headerRef} />
      <main>
        <LandingPage />
        <MainContent />
      </main>
      <Footer />
    </>
  );
}
