import React, { useEffect } from 'react';
import ContactDetails from '../components/ContactDetails';
import Header from '../components/Header';

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <main>
        <div className="contact">
          <div>
            <h1>Επικοινωνία</h1>
            <ContactDetails />
            <div
              style={{ gap: '20px', marginTop: '2rem' }}
              className="social flex-center w100"
            >
              <a target="_blank" href="/">
                <img
                  src="/images/Facebook.png"
                  className="footer-social"
                  alt="facebook logo"
                />
              </a>
              <a
                target="_blank"
                href="https://instagram.com/chicken_kings_"
                rel="noreferrer"
              >
                <img
                  src="/images/Instagram.png"
                  className="footer-social"
                  alt="instagram logo"
                />
              </a>
              <a target="_blank" href="/">
                <img
                  src="/images/Tik-Tok.png"
                  className="footer-social"
                  alt="tik tok logo"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="map">
          <iframe
            title="map"
            width="100%"
            height="100%"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Chicken%20kings%20Eth.%20Antistaseos%2032,%20Kalamaria%20551%2034+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </main>
    </>
  );
}
