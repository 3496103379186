import { useContext, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store.jsx';
import axios from 'axios';

export default function LoginPage() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { state, reduce } = useContext(Store);
  const { userInfo } = state;
  const userRef = useRef();
  const passRef = useRef();
  const remeberRef = useRef();

  useEffect(() => {
    if (userInfo) {
      navigate('/admin');
    }
  }, [navigate, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    let userName = userRef.current.value;
    let passWord = passRef.current.value;
    let remember = remeberRef.current.checked;
    try {
      // setupt the endpoints to login
      const { data } = await axios.post('/login', {
        userName,
        passWord,
        remember
      });
      if (data) {
        //reduce to loging and redirect
      } else {
        // toast the wrong cred message
      }
    } catch (error) {
      console.log(error);
    }
    console.log(userName, passWord, remember);
  };
  return (
    <main className="small-container">
      <h3 className="mb-4 text-center">Admin Log In</h3>
      <Form onSubmit={submitHandler}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control ref={userRef} type="text" placeholder="Enter email" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control ref={passRef} type="password" placeholder="Password" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check ref={remeberRef} type="checkbox" label="Remember me" />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </main>
  );
}
