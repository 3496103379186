import React, { useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Header(props, ref) {
  const navRef = useRef();
  const [menuOpen, setMenuOper] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const activePath = location.pathname;
  const isHomePage = activePath === '/';

  function toggle() {
    navRef.current.classList.toggle('open');
    setMenuOper((prev) => !prev);
  }
  function clearMenu() {
    if (navRef.current.classList.contains('open')) {
      toggle();
    }
  }
  function navigateTo(loc) {
    clearMenu();
    navigate(loc);
  }
  function handleKeyDown(event) {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      toggle();
    }
  }

  return (
    <header className="fixed" ref={ref}>
      <nav role="navigation">
        <div
          className="logoContainer"
          aria-label="logo of chicken kings and navigate to homepage"
          onClick={() => navigateTo('/')}
        >
          <img
            src={
              isHomePage && !menuOpen
                ? '/images/white-letter.png'
                : '/images/transparent-outside.png'
            }
            className="logo rotate"
            alt="logo of Chicken Kings outer letters"
          />
          <img
            src={
              isHomePage && !menuOpen
                ? '/images/white-inside.png'
                : '/images/transparent-inside.png'
            }
            className="logo"
            alt="logo of Chicken Kings inside icon"
          />
        </div>
        <div
          tabIndex={0}
          aria-label="menu toggle"
          aria-expanded={menuOpen}
          onKeyDown={handleKeyDown}
          className={
            menuOpen ? 'open-menu flex-column opened' : 'open-menu flex-column'
          }
          onClick={toggle}
        >
          <span
            style={{ backgroundColor: !isHomePage && '#5f0310' }}
            className="line"
          ></span>
          <span
            style={{ backgroundColor: !isHomePage && '#5f0310' }}
            className="line"
          ></span>
          <span
            style={{ backgroundColor: !isHomePage && '#5f0310' }}
            className="line"
          ></span>
          <div className="sr-only">Toggle menu</div>
        </div>

        <ul ref={navRef} className={isHomePage ? 'menuList home' : 'menuList'}>
          <li tabIndex={menuOpen ? 1 : -1} onClick={clearMenu}>
            <Link to="/" className={activePath === '/' ? 'active' : ''}>
              Αρχική
            </Link>
          </li>
          <li tabIndex={menuOpen ? 1 : -1} onClick={clearMenu}>
            <Link to="/menu" className={activePath === '/menu' ? 'active' : ''}>
              Μενού
            </Link>
          </li>
          <li tabIndex={menuOpen ? 1 : -1} onClick={clearMenu}>
            <Link
              to="/contact"
              className={activePath === '/contact' ? 'active' : ''}
            >
              Επικοινωνία
            </Link>
          </li>
          {/* {menuOpen &&   )} */}
        </ul>
        {/* <button onClick={toggle} className="navBtn"> */}

        {/* </button> */}
      </nav>
    </header>
  );
}
export default React.forwardRef(Header);
