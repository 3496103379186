import React from 'react';

export default function ContactDetails() {
  return (
    <div className="contact-details-home">
      <div className="column-2">
        <h3 className="details-title">Τηλέφωνο</h3>
        <p>2313 253-416</p>
      </div>
      <div className="column-2">
        <h3 className="details-title">Διεύθυνση</h3>
        <p>Εθνικής Αντιστάσεως 32, Καλαμαριά 55134 </p>
      </div>
      <div className="column-2">
        <h3 className="details-title">Ωράριο λειτουργείας</h3>
        <div>
          <div className="working-hours">
            <span>Δευτέρα</span> <span>12 μμ - 12 πμ</span>
          </div>
          <div className="working-hours">
            <span>Τρίτη</span> <span>12 μμ - 12 πμ</span>
          </div>
          <div className="working-hours">
            <span>Τετάρτη</span> <span>12 μμ - 12 πμ</span>
          </div>
          <div className="working-hours">
            <span>Πέμπτη</span> <span>12 μμ - 12 πμ</span>
          </div>
          <div className="working-hours">
            <span>Παρασκευή</span> <span>12 μμ - 1 πμ</span>
          </div>
          <div className="working-hours">
            <span>Σάββατο</span> <span>12 μμ - 1 πμ</span>
          </div>
          <div className="working-hours">
            <span>Κυριακή</span> <span>12 μμ - 11:30 μμ</span>
          </div>
        </div>
      </div>
    </div>
  );
}
