import React from 'react';
import FeatureMenu from './FeatureMenu';

export default function MainContent() {
  return (
    <div id="main">
      {/* <div tabIndex={0} className="container contact-home">
        <h2>Επικοινωνήστε μαζί μας</h2>
        <ContactDetails />
      </div> */}
      <div
        tabIndex={0}
        className="container about"
        style={{ paddingBlock: '4rem 6rem' }}
      >
        <h2 style={{ marginBottom: '1.5rem', textAlign: 'center' }}>
          Καλωσορίσατε στο Chicken Kings
        </h2>
        <p>
          Στο εστιατόριό μας, η αγάπη για το κοτόπουλο φαίνεται σε κάθε μπουκιά!
          Χρησιμοποιούμε μόνο φρέσκα και ποιοτικά υλικά για να δημιουργήσουμε το
          απόλυτο κοτόπουλο, που θα σας ξετρελάνει. Είτε είστε λάτρεις του
          τραγανού κοτόπουλου, είτε αναζητάτε μια νόστιμη επιλογή για το γεύμα
          σας, η επίσκεψή σας στο εστιατόριό μας θα είναι μια αξέχαστη γευστική
          εμπειρία!
        </p>
        <p>
          Από τηγανητό κοτόπουλο, μπέργκερ μέχρι και γκριλ, έχουμε κάτι για όλα
          τα γούστα. Επιπλέον, προσφέρουμε μια μεγάλη ποικιλία από πλευρικά και
          σαλάτες για να συνοδεύσετε τα πιάτα σας και να δημιουργήσετε το τέλειο
          γεύμα. Όλα τα πιάτα μας μπορούν να συνοδευτούν με τις αγαπημένες σας
          σάλτσες για να προσθέσετε ακόμη περισσότερη γεύση στο γεύμα σας.
        </p>
        {/* <p>
          Επιπλέον, προσφέρουμε μια μεγάλη ποικιλία από πλευρικά και σαλάτες για
          να συνοδεύσετε τα πιάτα σας και να δημιουργήσετε το τέλειο γεύμα. Όλα
          τα πιάτα μας μπορούν να συνοδευτούν με τις αγαπημένες σας σάλτσες για
          να προσθέσετε ακόμη περισσότερη γεύση στο γεύμα σας.
        </p>
        <p>
          Στο εστιατόριο μας, η ποιότητα είναι πάντα πάνω από την ποσότητα.
          Παρέχουμε φιλική εξυπηρέτηση και είμαστε πάντα διαθέσιμοι να
          απαντήσουμε σε οποιαδήποτε ερώτηση ή ανησυχία σας.
        </p>
        <p>
          Ελάτε να δοκιμάσετε τα πιο νόστιμα πιάτα μας και να απολαύσετε την
          απόλυτη γεύση του κοτόπουλου! Θα είμαστε πάντα εδώ για να σας
          υποδεχθούμε με ένα χαμόγελο και να σας προσφέρουμε μια εμπειρία
          γευστικής απόλαυσης που θα σας μείνει αξέχαστη.
        </p> */}
      </div>

      <div tabIndex={0} style={{ paddingBlock: '0 3rem' }}>
        <FeatureMenu />
      </div>
      <div tabIndex={0} className="container delivery">
        <img
          src="/images/delivery.png"
          alt="delivery"
          aria-label="word delivery"
        />
        <div>
          <i style={{ marginRight: '0.5rem' }} className="fas fa-phone"></i>{' '}
          2313 253-416
        </div>
      </div>
    </div>
  );
}
