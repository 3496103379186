import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store.jsx';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';

export default function AdminPage() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { state, reduce } = useContext(Store);
  const { userInfo } = state;
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lang, setLang] = useState('en');

  useEffect(() => {
    if (!userInfo) {
      navigate('/admin/logIn');
    }
    const getCatgories = async () => {
      try {
        const { data } = await axios.get('/api/products');
        setCategories(data);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    getCatgories();
  }, [navigate, userInfo]);

  const [key, setKey] = useState('Categories');

  function changeLang() {
    setLang((prev) => (prev === 'en' ? 'el' : 'en'));
  }
  return (
    <main className="large-container">
      <button onClick={changeLang}>Change Lang</button>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="Categories" title="Categories">
          {loading ? (
            <div className="loading">loading</div>
          ) : (
            categories.map((c) => <li key={c._id}>{c[lang]}</li>)
          )}
        </Tab>
        <Tab eventKey="Products" title="Products">
          <div>List with the products</div>
        </Tab>
      </Tabs>
    </main>
  );
}
