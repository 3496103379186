import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import LadingFeatureProduct from './LadingFeatureProduct';

export default function LandingPage() {
  const [currentFeautred, setCurrentFeatured] = useState(0);
  const productRef = useRef(null);

  const featuredProducts = [
    {
      img: '/images/landing.jpeg',
      price: 15,
      title: 'Bucket Deal',
      details:
        '4 τεμ. φτερούγες ελαφρώς καυτερές, 4 τεμ. σνιτσελάκι φιλέτο κοτόπουλο, 4 τεμ. κοτομπουκιές με επίγευση τυριού και δύο σως της επιλογής σας + 500ml Coca Cola'
    },
    {
      img: '/images/burger.jpg',
      price: 9.8,
      title: 'The King (Black Angus)',
      details:
        'Μοσχαρίσιο μπιφτέκι 200 gr., αφράτο μπριός, σως μπέργκερ, τυρί τσένταρ, τραγανό μπέικον, πράσινη σαλάτα, ντομάτα, καραμελωμένα κρεμμύδια και σάλτσα BBQ. Συνοδεύεται με τηγανητές πατάτες (wedges).'
    },
    {
      img: '/images/grilled.jpg',
      price: 8.5,
      title: 'Το βλάχικο (μερίδα)',
      details:
        'Κοτόπουλο σουβλιστό στα κάρβουνα, αρωματισμένο με μπαχαρικά. Συνοδεύεται με τηγανητές πατάτες, τζατζίκι και πίτα στα κάρβουνα.'
    }
  ];
  function changeFeature(dir = 'right') {
    let anim = gsap.timeline();
    anim.to(productRef.current, { opacity: 0, duration: 0.4 });
    anim.add(() => {
      changeValue(dir);
    });
    anim.to(productRef.current, { opacity: 1, duration: 0.4 });
    // productRef.current.style.opacity = 1;
  }
  function changeValue(dir = 'right') {
    if (dir === 'left') {
      setCurrentFeatured((prev) => {
        let newIndx = prev - 1;
        if (newIndx < 0) newIndx = featuredProducts.length - 1;
        return newIndx;
      });
    } else {
      setCurrentFeatured((prev) => {
        let newIndx = prev + 1;
        if (newIndx >= featuredProducts.length) newIndx = 0;
        return newIndx;
      });
    }
  }
  useEffect(() => {
    const time = setTimeout(() => {
      changeFeature();
    }, 15000);
    return () => {
      clearTimeout(time);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFeautred]);
  return (
    <div className="menu-home">
      <LadingFeatureProduct
        ref={productRef}
        product={featuredProducts[currentFeautred]}
        changeFeature={changeFeature}
      />

      {/* <a href="#main" className="landing-scroll">
        <i className="fas fa-arrow-down"></i>
      </a> */}
    </div>
  );
}
