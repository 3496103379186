import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props, ref) {
  return (
    <div className="footer-container" ref={ref}>
      <div
        className="map"
        tabIndex={0}
        aria-label="Χάρτης για το πού βρίσκετε το εστιατόριο Chicken Kings"
      >
        <iframe
          title="map"
          width="100%"
          height="100%"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Chicken%20kings%20Eth.%20Antistaseos%2032,%20Kalamaria%20551%2034+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>
      <footer>
        {/* <h2 style={{ textAlign: 'center', marginBottom: '1rem' }}>
          Chicken Kings
        </h2> */}
        {/* <img
          src="/images/logo-black.jpg"
          className="footer-logo"
          alt="logo of chicken kings"
        /> */}
        <div className="logo-social-container">
          <div className="flex-column social-container">
            {/* <h4 className="social-title">Follow us</h4> */}
            <div className="social flex-center w100">
              <a target="_blank" href="https://facebook.com/" rel="noreferrer">
                <img
                  src="/images/Facebook.png"
                  className="footer-social"
                  alt="facebook logo"
                />
              </a>
              <a
                target="_blank"
                href="https://instagram.com/chicken_kings_"
                rel="noreferrer"
              >
                <img
                  src="/images/Instagram.png"
                  className="footer-social"
                  alt="instagram logo"
                />
              </a>
              <a target="_blank" href="https://tiktok.com/" rel="noreferrer">
                <img
                  src="/images/Tik-Tok.png"
                  className="footer-social"
                  alt="tik tok logo"
                />
              </a>
            </div>
          </div>

          <div className="flex-column social-container">
            <div className="footer-info">
              <span>
                <i className="fas fa-phone"></i> 2313 253-416{' '}
              </span>
            </div>
            <div className="footer-info">
              <span>
                <i className="fas fa-map-marker"></i> Εθν. Αντιστάσεως 32
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: '12px',
            marginTop: '2rem',
            textAlign: 'center',
            marginBottom: '5px'
          }}
        >
          Δευ-Πεμ: 12 μμ - 12 πμ
        </div>
        <div
          style={{
            fontSize: '12px',
            textAlign: 'center',
            marginBottom: '5px'
          }}
        >
          Παρ-Σαβ: 12 μμ - 1 πμ
        </div>
        <div style={{ fontSize: '12px', textAlign: 'center' }}>
          Κυρ: 12 μμ - 11:30 μμ
        </div>
        <div className="footer-nav-container">
          <nav>
            <Link to="/">Home</Link> | <Link to="/menu">Menu</Link> |{' '}
            <Link to="/contact">Contact</Link>
          </nav>
        </div>
        <div style={{ textAlign: 'center', fontSize: '11px' }}>
          Chicken kings &#169; copyright
        </div>
      </footer>
    </div>
  );
}
export default React.forwardRef(Footer);
