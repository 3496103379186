import { createContext, useReducer } from 'react';

export const Store = createContext();

const initialState = {
  userInfo: {
    isAdmin: true,
    token: 'bearer ifuhdoigfhodfghjdfhgkj'
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'USER_SIGN_IN':
      return { ...state, userInfo: action.payload };
    case 'USER_SIGN_OUT':
      return { ...state, userInfo: null };
    default:
      return { ...state };
  }
};

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
