import { gsap } from 'gsap';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

function LadingFeatureProduct({ product, changeFeature }, ref) {
  const progressRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(progressRef.current, {
      width: '100%',
      duration: 15,
      ease: 'linear'
    }).to(
      imgRef.current,
      {
        backgroundPosition: '100% 100%',
        duration: 15,
        ease: 'linear'
      },
      0
    );
    return () => {
      tl.kill();
      onSlideChange();
    };
  }, [product]);

  const onSlideChange = () => {
    // Reset progress bar and image to start position
    gsap.set(progressRef.current, { width: 0 });
    gsap.set(imgRef.current, { backgroundPosition: '0% 0%' });
  };

  return (
    <div className="landing-product-container" ref={ref}>
      <div className="lading-image-contaier">
        <div className="feature-navigate">
          <button
            className="feature-navigate-button"
            onClick={() => changeFeature('left')}
          >
            {' '}
            <span className="sr-only">Navigate slider to left</span>
            <i className="fas fa-chevron-left"></i>
          </button>
          <button
            className="feature-navigate-button"
            onClick={() => changeFeature()}
          >
            {' '}
            <span className="sr-only">Navigate slider to right</span>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
        <div
          className="landing-img"
          ref={imgRef}
          style={{ backgroundImage: `url(${product.img})` }}
        ></div>
        <div
          aria-label="progress bar of the carousel change"
          className="progress-bar"
        >
          <div ref={progressRef} className="progress"></div>
        </div>
        <div className="black-overlay"></div>
      </div>
      <div className="landing-product" data-price={product.price}>
        <h2>{product.title}</h2>
        <p>{product.details}</p>
        <span className="landing-price flex-center">
          {product.price}&#8364;
        </span>
        <Link to="/menu" className="menu-link flex-center">
          <span
            className="romb-shape"
            aria-label="decorative icon for the link to menu"
          >
            ⬧&nbsp;&nbsp;&nbsp;
          </span>
          Δείτε το Menu
          <span
            className="romb-shape"
            aria-label="decorative icon for the link to menu"
          >
            ⬧&nbsp;&nbsp;&nbsp;
          </span>
        </Link>
      </div>
    </div>
  );
}

export default React.forwardRef(LadingFeatureProduct);
