import React, { useEffect, useRef } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import products from '../menu.json';

const groupedProducts = products.products.reduce((a, c) => {
  if (!a[c.categoryId]) a[c.categoryId] = [];
  a[c.categoryId].push(c);
  return a;
}, {});
export default function Menu() {
  const lag = 'el';
  const headerRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.05) {
        headerRef.current.classList.add('bg-menu');
      } else headerRef.current.classList.remove('bg-menu');
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Header ref={headerRef} />
      <main>
        <div className="menu-page">
          <h1 className="page-header">Menu</h1>
          <div className="main-menu">
            <div className="col-one">
              {products.categories.slice(0, 8).map((cat) => (
                <div
                  className={
                    cat._id === 'AcHwbYRMfG'
                      ? 'category-container extras'
                      : 'category-container'
                  }
                  key={cat._id}
                >
                  <h2 aria-label="Κατηγορία μενού" className="category-title">
                    {cat.lang[lag]}
                  </h2>
                  <ul className="product-list">
                    {groupedProducts[cat._id].map((product) =>
                      product.extra ? (
                        <li className="product-item-extra" key={product._id}>
                          {product.lang[lag]} +{' '}
                          {product.price.toFixed(2).toString()} &#8364;
                        </li>
                      ) : (
                        <li
                          className={
                            product.bucket && !product.menu
                              ? 'product-item small'
                              : 'product-item'
                          }
                          key={product._id}
                        >
                          <div className="product-title">
                            <h3 aria-label="Όνομα προϊόντος">
                              {product.lang[lag]}
                            </h3>
                            {product.description && (
                              <span
                                aria-label="Περιγραφή προϊόντος"
                                className="description"
                              >
                                {product.description}
                              </span>
                            )}
                          </div>
                          <span aria-label="Τιμή προϊόντος">
                            {product.price.toFixed(2).toString()} &#8364;{' '}
                            {product.bucket &&
                              !product.menu &&
                              (lag === 'el' ? ' / τεμ' : ' / piece')}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              ))}
            </div>
            <div className="col-two">
              {products.categories.slice(8).map((cat) => (
                <div
                  className={
                    cat._id === 'AcHwbYRMfG'
                      ? 'category-container extras'
                      : 'category-container'
                  }
                  key={cat._id}
                >
                  <h2 aria-label="Κατηγορία μενού" className="category-title">
                    {cat.lang[lag]}
                  </h2>
                  <ul className="product-list">
                    {groupedProducts[cat._id].map((product) =>
                      product.extra ? (
                        <li className="product-item-extra" key={product._id}>
                          {product.lang[lag]} +{' '}
                          {product.price.toFixed(2).toString()} &#8364;
                        </li>
                      ) : (
                        <li
                          className={
                            product.bucket && !product.menu
                              ? 'product-item small'
                              : 'product-item'
                          }
                          key={product._id}
                        >
                          <div className="product-title">
                            <h3 aria-label="Όνομα προϊόντος">
                              {product.lang[lag]}
                            </h3>
                            {product.description && (
                              <span
                                aria-label="Περιγραφή προϊόντος"
                                className="description"
                              >
                                {product.description}
                              </span>
                            )}
                          </div>
                          <span aria-label="Τιμή προϊόντος">
                            {product.price.toFixed(2).toString()} &#8364;{' '}
                            {product.bucket &&
                              !product.menu &&
                              (lag === 'el' ? ' / τεμ' : ' / piece')}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <span style={{ paddingBottom: '1rem', display: 'block' }}>
            * Κατεψυγμένα
          </span>
        </div>
      </main>
      <Footer />
    </>
  );
}
